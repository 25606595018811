import { KeyCodes } from 'types/common';

/**
 * Returns click event if ENTER or SPACE key is pressed
 * @param onClick ClickEvent
 * @returns onClick(e)
 */

export type CustomKeyDownProps = {
  onEnter?: GenericHandler;
  onSpace?: GenericHandler;
  onEscape?: GenericHandler;
  onEnterOrSpace?: GenericHandler;
  onTab?: GenericHandler;
  onShiftTab?: GenericHandler;
};

export const useCustomKeyDown = ({
  onEnter,
  onSpace,
  onEscape,
  onEnterOrSpace,
  onTab,
  onShiftTab
}: CustomKeyDownProps) => {
  return (e) => {
    const actions = {
      [KeyCodes.ENTER]: () => {
        return onEnterOrSpace ? onEnterOrSpace?.(e) : onEnter?.(e);
      },
      [KeyCodes.SPACE]: () => {
        return onEnterOrSpace ? onEnterOrSpace?.(e) : onSpace?.(e);
      },
      [KeyCodes.ESCAPE]: () => {
        return onEscape?.(e);
      },
      [KeyCodes.TAB]: () => {
        return e.shiftKey ? onShiftTab?.(e) : onTab?.(e);
      }
    };

    return actions[e.keyCode]?.();
  };
};

import React from 'react';
import { toast, ToastOptions } from 'react-hot-toast';

import { Alert, AlertType } from './Alert';

export type GlobalAlertConfig = Omit<AlertType, 'toast'> &
  Pick<ToastOptions, 'duration' | 'position' | 'id'>;

export const globalAlert = ({ intent, duration, position, id, ...config }: GlobalAlertConfig) =>
  toast.custom(() => <Alert {...config} intent={intent ?? 'info'} />, {
    position,
    duration,
    id
  });

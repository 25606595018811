import { Breakpoint, BreakpointProp, breakpointsEnum } from 'hooks/useUI/useBreakpoints';

export type CalculateBreakpointProps = {
  breakpointUp?: BreakpointProp;
  breakpointDown?: BreakpointProp;
};

export const getMiddleBreakpointsKeys = (
  breakpointsKeys: Breakpoint[],
  breakpointUpIndex: number,
  breakpointDownIndex: number
) => {
  const breakpointsIntersectionArray = breakpointsKeys.slice(
    breakpointUpIndex,
    breakpointDownIndex + 1
  );
  return {
    breakpointsUp: breakpointsIntersectionArray,
    breakpointsDown: breakpointsIntersectionArray
  };
};

export const getBreakpointsKeys = (
  breakpointsKeys: Breakpoint[],
  breakpointUpIndex: number,
  breakpointDownIndex: number
) =>
  breakpointUpIndex < breakpointDownIndex
    ? getMiddleBreakpointsKeys(breakpointsKeys, breakpointUpIndex, breakpointDownIndex)
    : {
        breakpointsUp: breakpointsKeys.slice(breakpointUpIndex),
        breakpointsDown: breakpointsKeys.slice(0, breakpointDownIndex + 1)
      };

export const getDefaultBreakpointsKeys = (breakpointsKeys: Breakpoint[]) => {
  return { breakpointsUp: breakpointsKeys, breakpointsDown: breakpointsKeys };
};

export const calculateBreakpoints = ({
  breakpointUp = false,
  breakpointDown = false
}: CalculateBreakpointProps): {
  breakpointsUp: Breakpoint[];
  breakpointsDown: Breakpoint[];
} => {
  const breakpointsKeys: Breakpoint[] = (
    Object.keys(breakpointsEnum) as Array<keyof typeof breakpointsEnum>
  ).map((key) => key);

  const breakpointUpIndex = breakpointUp && breakpointsKeys.indexOf(breakpointUp);
  const breakpointDownIndex = breakpointDown && breakpointsKeys.indexOf(breakpointDown);

  switch (breakpointUp) {
    case false: {
      return breakpointDown
        ? {
            breakpointsUp: [],
            breakpointsDown: breakpointsKeys.slice(0, breakpointDownIndex + 1)
          }
        : getDefaultBreakpointsKeys(breakpointsKeys);
    }
    default: {
      return breakpointDown
        ? getBreakpointsKeys(breakpointsKeys, breakpointUpIndex, breakpointDownIndex)
        : {
            breakpointsUp: breakpointsKeys.slice(breakpointUpIndex),
            breakpointsDown: []
          };
    }
  }
};

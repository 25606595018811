import { TransitionClasses } from '@headlessui/react';
import cx from 'clsx';

export const opacity100EnterTransition: TransitionClasses = {
  enter: 'transition',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100'
};

export const opacity100LeaveTransition: TransitionClasses = {
  leave: 'transition',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0'
};

export const opacity100Transition: TransitionClasses = {
  ...opacity100EnterTransition,
  ...opacity100LeaveTransition
};

export const opacityEaseInOutTransition: TransitionClasses = {
  ...opacity100Transition,
  enter: cx(opacity100Transition.enter, 'ease-in-out'),
  leave: cx(opacity100Transition.leave, 'ease-in-out')
};

export const opacity100EaseTransition: TransitionClasses = {
  ...opacity100Transition,
  enter: cx(opacity100Transition.enter, 'ease-out'),
  leave: cx(opacity100Transition.leave, 'ease-in')
};

export const opacity100EaseOutTransition: TransitionClasses = {
  ...opacity100Transition,
  enter: cx(opacity100Transition.enter, 'ease-out'),
  leave: cx(opacity100Transition.leave, 'ease-out')
};

export const opacity100ScaleTransition: TransitionClasses = {
  ...opacity100EaseOutTransition,
  enter: cx(opacity100EaseOutTransition.enter, 'duration-100'),
  enterFrom: cx(opacity100EaseOutTransition.enterFrom, 'scale-95'),
  enterTo: cx(opacity100EaseOutTransition.enterTo, 'scale-100'),
  leave: cx(opacity100EaseOutTransition.leave, 'duration-75'),
  leaveFrom: cx(opacity100EaseOutTransition.leaveFrom, 'scale-100'),
  leaveTo: cx(opacity100EaseOutTransition.leaveTo, 'scale-95')
};

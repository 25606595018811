import { useToggle } from 'hooks/useToggle';
import { ChevronIcon } from 'icons/monochrome/ChevronIcon';
import { makeStyles } from 'lib/makeStyles';
import { easeInOut150 } from 'lib/transitionProps';
import React, { memo } from 'react';

const useStyles = makeStyles<ExpandPanelButtonProps>()({
  root: (props) => [props?.className],
  inner: (props) => [
    'bg-secondaryBackground hover:bg-secondaryBackgroundHover',
    'border border-secondaryBorder hover:border-secondaryBorderHover',
    'px-1 py-3',
    'shadow hover:shadow-lg',
    {
      'rounded-l-md': !props.reverse,
      'rounded-r-md': props.reverse
    },
    'transition-all'
  ],
  chevronIcon: (props) => [!props?.show ? 'rotate-180' : 'rotate-270', 'h-8', easeInOut150]
});

export type ExpandPanelButtonProps = {
  handleClick?: GenericHandler;
  show?: boolean;
  reverse?: boolean;
} & ParentClassNameProp &
  Pick<JSX.IntrinsicElements['div'], 'style'>;

export const ExpandPanelButton = memo(
  ({ handleClick, show = false, reverse = false, className, style }: ExpandPanelButtonProps) => {
    const { toggle, status } = useToggle();
    const styles = useStyles({ reverse, show: show || status, className });
    return (
      <button className={styles.root} onClick={handleClick ?? toggle} style={style}>
        <div className={styles.inner}>
          <ChevronIcon className={styles.chevronIcon} />
        </div>
      </button>
    );
  }
);

ExpandPanelButton.displayName = 'ExpandPanelButton';

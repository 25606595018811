import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const TrailerIcon = React.memo((props: GlyphProps) => (
  <Glyph width={24} height={24} {...props}>
    <g clipPath="url(#clip0_608:85)">
      <path
        d="M8.25 18.25H10.5781"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 18.25H19.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75 15.25V7.75C5.75 7.19772 6.19772 6.75 6.75 6.75H16.25C16.8023 6.75 17.25 7.19772 17.25 7.75V18.25H14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="6.5"
        cy="17.5"
        r="1.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12.5"
        cy="17.5"
        r="1.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 11.25H13.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_608:85">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

TrailerIcon.displayName = 'TrailerIcon';

export { TrailerIcon };

import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const CheckboxIcon = React.memo((props: GlyphProps) => (
  <Glyph width={8} height={8} {...props}>
    <path
      d="M1 4.5L3 7L7 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Glyph>
));

CheckboxIcon.displayName = 'CheckboxIcon';

export { CheckboxIcon };

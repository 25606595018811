import { getInteraction, makeStyles } from 'lib/makeStyles';
import { useMemo } from 'react';
import { makeTypographyVariantStyles } from 'styles/variantStyles/typographyVariantStyles';

import {
  TypographyAlignProps,
  TypographyColorProps,
  TypographyWhitespaceProps,
  TypographyWrapperProps
} from './index';

export const useTypographyColorStyles = ({
  color,
  dark,
  active,
  disabled,
  enableHover,
  disableActiveColor = false
}: TypographyColorProps) => {
  return useMemo(
    () => ({
      color: makeTypographyVariantStyles(enableHover)({
        intent: color,
        mode: dark ? 'dark' : 'default',
        interaction: getInteraction({ checked: !disableActiveColor && active, disabled })
      })
    }),
    [color, dark, active, disabled, enableHover, disableActiveColor]
  );
};

export const useWrapperStyles = makeStyles<TypographyWrapperProps>()({
  wrapper: ({ className }: TypographyWrapperProps) => [
    {
      block:
        !className?.includes('block') &&
        !className?.includes('inline') &&
        !className?.includes('hidden')
    },
    className
  ]
});

export const useTypographyAlignStyles = makeStyles<TypographyAlignProps>()({
  align: ({ align }: TypographyAlignProps) => [align && `text-${align}`]
});

export const useTypographyWhitespaceStyles = makeStyles<TypographyWhitespaceProps>()({
  whitespace: ({ whitespace }: TypographyWhitespaceProps) => [
    whitespace && `whitespace-${whitespace}`
  ]
});

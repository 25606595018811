import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

type NavigationIconProps = { solid?: boolean } & GlyphProps;

export const NavigationIcon = React.memo(({ solid, ...props }: NavigationIconProps) => (
  <Glyph width={24} height={24} {...props}>
    <path
      d="M12 4.75L5.75 19.25L12 15.75L18.25 19.25L12 4.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={solid && 'currentColor'}
    />
  </Glyph>
));

NavigationIcon.displayName = 'NavigationIcon';

import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const DangerIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM8 4C8.55228 4 9 4.44772 9 5V7.5C9 8.05228 8.55228 8.5 8 8.5C7.44772 8.5 7 8.05228 7 7.5V5C7 4.44772 7.44772 4 8 4ZM8 12C8.55228 12 9 11.5523 9 11C9 10.4477 8.55228 10 8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12Z"
      fill="#D12702"
    />
  </Glyph>
));

DangerIcon.displayName = 'DangerIcon';

export { DangerIcon };

import { Asset } from 'contentful';
import {
  SerializedAsset,
  SerializedAuthor,
  SerializedBlogPost,
  SerializedComponent,
  SerializedComponentItem,
  SerializedLink,
  SerializedPage,
  SerializedRichTextSection
} from 'types/contentful';
import {
  IAuthor,
  IBlogPost,
  IContentSection,
  ILink,
  IPage,
  IUiComponent,
  IUiComponentItem
} from 'types/generated/contentful';

export const serializeAsset = (asset: Asset): SerializedAsset => {
  return {
    ...(asset.fields.file || {}),
    id: asset.sys.id,
    title: asset.fields.title,
    description: asset.fields.description
  };
};

export const serializeLink = (link: ILink): SerializedLink => {
  return {
    ...link.fields,
    id: link.sys.id
  };
};

export const serializeItem = (uiItem: IUiComponentItem): SerializedComponentItem => {
  if (!uiItem.fields) {
    return null;
  }

  return {
    ...uiItem.fields,
    assets: uiItem.fields.assets && uiItem.fields.assets.map(serializeAsset),
    id: uiItem.sys.id,
    items: uiItem.fields.items && uiItem.fields.items.map(serializeItem),
    primaryLink: uiItem.fields.primaryLink && serializeLink(uiItem.fields.primaryLink),
    secondaryLink: uiItem.fields.secondaryLink && serializeLink(uiItem.fields.secondaryLink)
  };
};

export const serializeRichText = (uiItem: IContentSection): SerializedRichTextSection => {
  return {
    ...uiItem.fields,
    id: uiItem.sys.id
  };
};

export const serializeComponent = (uiItem: IUiComponent): SerializedComponent => {
  return {
    ...uiItem.fields,
    type: uiItem.fields.type,
    richTextSections:
      uiItem.fields.richTextSections && uiItem.fields.richTextSections.map(serializeRichText),
    items: uiItem.fields.items && uiItem.fields.items.map(serializeItem),
    id: uiItem.sys.id
  };
};

export const serializeAuthor = (author: IAuthor): SerializedAuthor => {
  return {
    ...author.fields,
    avatar: serializeAsset(author.fields.avatar),
    id: author.sys.id
  };
};

export const serializeBlogPost = (blogPost: IBlogPost): SerializedBlogPost => {
  if (!blogPost?.fields) {
    return;
  }

  return {
    ...blogPost.fields,
    featuredImage: blogPost.fields.featuredImage && serializeAsset(blogPost.fields.featuredImage),
    id: blogPost.sys.id,
    author: serializeAuthor(blogPost.fields.author),
    createdAt: blogPost.sys.createdAt
  };
};

export const serializePage = (page: IPage): SerializedPage => {
  return {
    ...page.fields,
    id: page?.sys?.id,
    createdAt: page?.sys?.createdAt,
    updatedAt: page?.sys?.updatedAt,
    previewImage: page?.fields?.previewImage && serializeAsset(page.fields.previewImage)
  };
};

import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const ParkingIcon = React.memo((props: GlyphProps) => (
  <Glyph width={24} height={24} {...props}>
    <path
      d="M17.2502 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.2502C18.3548 4.75 19.2502 5.64543 19.2502 6.75V17.25C19.2502 18.3546 18.3548 19.25 17.2502 19.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 15.25V12.25M9.75 12.25V8.75H12.5C13.4665 8.75 14.25 9.5335 14.25 10.5C14.25 11.4665 13.4665 12.25 12.5 12.25H9.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Glyph>
));

ParkingIcon.displayName = 'ParkingIcon';

export { ParkingIcon };

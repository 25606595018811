import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const ChevronIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29289 4.29289C5.90237 4.68342 5.90237 5.31658 6.29289 5.70711L8.58579 8L6.29289 10.2929C5.90237 10.6834 5.90237 11.3166 6.29289 11.7071C6.68342 12.0976 7.31658 12.0976 7.70711 11.7071L10.7071 8.70711C11.0976 8.31658 11.0976 7.68342 10.7071 7.29289L7.70711 4.29289C7.31658 3.90237 6.68342 3.90237 6.29289 4.29289Z"
      fill="currentColor"
    />
  </Glyph>
));

ChevronIcon.displayName = 'ChevronIcon';

export { ChevronIcon };

import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const SuccessIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={17} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15.5C11.866 15.5 15 12.366 15 8.5C15 4.63401 11.866 1.5 8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 12.366 4.13401 15.5 8 15.5ZM11.3 7.1C11.6314 6.65817 11.5418 6.03137 11.1 5.7C10.6582 5.36863 10.0314 5.45817 9.7 5.9L7.39181 8.97759L6.20711 7.79289C5.81658 7.40237 5.18342 7.40237 4.79289 7.79289C4.40237 8.18342 4.40237 8.81658 4.79289 9.20711L6.79289 11.2071C6.99788 11.4121 7.28173 11.518 7.57089 11.4975C7.86005 11.4769 8.12607 11.3319 8.3 11.1L11.3 7.1Z"
      fill="currentColor"
    />
  </Glyph>
));

SuccessIcon.displayName = 'SuccessIcon';

export { SuccessIcon };

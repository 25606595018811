import { PanelItem } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';

import { useGridOverlapStyles } from '../../../styles';
import { AlertTypes } from './Alert';
import { useAlertAccentVariantStyles } from './alertVariantStyles';

export const useStyles = makeStyles<
  ParentClassNameProp & {
    horizontalStyles: string;
    verticalStyles: string;
    accentColorStyles: string;
  }
>()({
  root: (props) => ['absolute top-[-1px] left-[-1px]', props?.className],
  verticalLines: (props) => ['h-[120px]', props?.verticalStyles, props?.accentColorStyles],
  verticalLine0: (props) => ['h-[60px]', props?.verticalStyles, props?.accentColorStyles],
  verticalLine1: (props) => ['h-[40px]', props?.verticalStyles, props?.accentColorStyles],
  horizontalLines: (props) => ['w-[120px]', props?.horizontalStyles, props?.accentColorStyles],
  horizontalLine0: (props) => ['w-[30px]', props?.horizontalStyles, props?.accentColorStyles],
  horizontalLine1: (props) => ['w-[20px]', props?.horizontalStyles, props?.accentColorStyles]
});

type AlertAccentGridProps = {
  intent: AlertTypes;
};

export const AlertAccentGrid = memo(({ intent }: AlertAccentGridProps) => {
  const gridOverlapStyles = useGridOverlapStyles();

  const accentGridLines = useAlertAccentVariantStyles({
    baseClassNames: ['via-transparent']
  })({
    intent,
    mode: 'default',
    interaction: 'hover',
    prominence: 'default'
  });

  const styles = useStyles({
    className: gridOverlapStyles.grid,
    accentColorStyles: accentGridLines,
    horizontalStyles: 'h-[1px] bg-gradient-to-r',
    verticalStyles: 'w-[1px] bg-gradient-to-b'
  });

  return (
    <div className={styles.root}>
      <PanelItem
        column
        padding={false}
        spacing="LG"
        align="start"
        className={gridOverlapStyles.gridItem}>
        {[...Array(4)].map((_, i) => (
          <div key={`alert_accent_horizontal_${i}`} className={styles.horizontalLines} />
        ))}
        <div className={styles.horizontalLine0} />
        <div className={styles.horizontalLine1} />
      </PanelItem>
      <PanelItem padding={false} spacing="LG" align="start" className={gridOverlapStyles.gridItem}>
        {[...Array(4)].map((_, i) => (
          <div key={`alert_accent_vertical_${i}`} className={styles.verticalLines} />
        ))}
        <div className={styles.verticalLine0} />
        <div className={styles.verticalLine1} />
      </PanelItem>
    </div>
  );
});

AlertAccentGrid.displayName = 'AlertAccentGrid';

import { EnableHoverType } from 'components/common/Typography/props';
import { makeVariantStyles } from 'lib/makeStyles';
import { useMemo } from 'react';

export type TypographyCustomIntent = 'white' | 'tmBlue';

export const getPrimaryTextHover = (enableHover: EnableHoverType) =>
  enableHover === 'group' ? 'group-hover:text-primaryTextHover' : 'hover:text-primaryTextHover';

export const getPrimaryTextDarkHover = (enableHover: EnableHoverType) =>
  enableHover === 'group'
    ? 'group-hover:text-primaryTextDarkHover'
    : 'hover:text-primaryTextDarkHover';

export const getSecondaryTextHover = (enableHover: EnableHoverType) =>
  enableHover === 'group' ? 'group-hover:text-secondaryTextHover' : 'hover:text-secondaryTextHover';

export const getSecondaryTextDarkHover = (enableHover: EnableHoverType) =>
  enableHover === 'group'
    ? 'group-hover:text-secondaryTextDarkHover'
    : 'hover:text-secondaryTextDarkHover';

export const getSuccessTextHover = (enableHover: EnableHoverType) =>
  enableHover === 'group' ? 'group-hover:text-successTextHover' : 'hover:text-successTextHover';

export const getDangerTextHover = (enableHover: EnableHoverType) =>
  enableHover === 'group' ? 'group-hover:text-dangerTextHover' : 'hover:text-dangerTextHover';

export const getInfoTextHover = (enableHover: EnableHoverType) =>
  enableHover === 'group' ? 'group-hover:text-infoTextHover' : 'hover:text-infoTextHover';

export const makeTypographyVariantStyles = (enableHover: EnableHoverType = false) =>
  makeVariantStyles({
    compoundVariants: [
      // white and tmBlue are set in makeVariantStyles
      {
        intent: 'primary',
        mode: 'default',
        interaction: 'hover',
        class: ['text-primaryText', Boolean(enableHover) && getPrimaryTextHover(enableHover)]
      },
      {
        intent: 'primary',
        mode: 'default',
        interaction: 'active',
        class: ['text-primaryTextActive']
      },
      {
        intent: 'primary',
        mode: 'default',
        interaction: 'disabled',
        class: ['text-primaryTextDisabled']
      },
      {
        intent: 'primary',
        mode: 'dark',
        interaction: 'hover',
        class: [
          'text-primaryTextDark',
          Boolean(enableHover) && getPrimaryTextDarkHover(enableHover)
        ]
      },
      {
        intent: 'primary',
        mode: 'dark',
        interaction: 'active',
        class: ['text-primaryTextDarkActive']
      },
      {
        intent: 'primary',
        mode: 'dark',
        interaction: 'disabled',
        class: ['text-primaryTextDarkDisabled']
      },
      {
        intent: 'secondary',
        mode: 'default',
        interaction: 'hover',
        class: ['text-secondaryText', Boolean(enableHover) && getSecondaryTextHover(enableHover)]
      },
      {
        intent: 'secondary',
        mode: 'default',
        interaction: 'active',
        class: ['text-secondaryTextActive']
      },
      {
        intent: 'secondary',
        mode: 'default',
        interaction: 'disabled',
        class: ['text-secondaryTextDisabled']
      },
      {
        intent: 'secondary',
        mode: 'dark',
        interaction: 'hover',
        class: [
          'text-secondaryTextDark',
          Boolean(enableHover) && getSecondaryTextDarkHover(enableHover)
        ]
      },
      {
        intent: 'secondary',
        mode: 'dark',
        interaction: 'active',
        class: ['text-secondaryTextDarkActive']
      },
      {
        intent: 'secondary',
        mode: 'dark',
        interaction: 'disabled',
        class: ['text-secondaryTextDarkDisabled']
      },
      {
        intent: 'neutral',
        mode: 'default',
        interaction: 'hover',
        // TODO Update neutral color to be accessible
        class: ['text-secondaryText']
      },
      {
        intent: 'neutral',
        mode: 'dark',
        interaction: 'hover',
        class: ['text-neutralTextDark']
      },
      {
        intent: 'success',
        mode: 'default',
        interaction: 'hover',
        class: ['text-successText', Boolean(enableHover) && getSuccessTextHover(enableHover)]
      },
      {
        intent: 'danger',
        mode: 'default',
        interaction: 'hover',
        class: ['text-dangerText', Boolean(enableHover) && getDangerTextHover(enableHover)]
      },
      {
        intent: 'info',
        mode: 'default',
        interaction: 'hover',
        class: ['text-infoText', Boolean(enableHover) && getInfoTextHover(enableHover)]
      }
    ]
  });

export const useTypographyVariantStyles = (enableHover: EnableHoverType) =>
  useMemo(() => makeTypographyVariantStyles(enableHover), [enableHover]);

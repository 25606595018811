import { useCallback, useEffect } from 'react';
import { KeyCodes } from 'types/common';

/**
 * Adds event listener for ESCAPE keydown
 * @param enable boolean
 * @param callback (e) => void
 * @returns useEffect
 */

export type EscapeKeydownListener = {
  enable: boolean;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  callback: (e?: any) => void;
};

export const useKeydownEscapeListener = ({ enable, callback }: EscapeKeydownListener) => {
  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === KeyCodes.ESCAPE) {
        return callback(e);
      }
    },
    [enable]
  );

  return useEffect(() => {
    const onKeyDown = (e) => handleKeyDown(e);

    enable && document.addEventListener('keydown', onKeyDown);

    return () => {
      enable && document.removeEventListener('keydown', onKeyDown);
    };
  }, [enable]);
};

import { MetaText, TypographyOwnProps } from 'components/common/Typography';
import { PanelBasic, PanelBasicProps } from 'components/layout/Panel/PanelBasic';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export type CardProps = ParentClassNameProp & {
  title?: string | React.ReactNode;
  containerClassName?: string;
} & ChildrenProp &
  PanelBasicProps &
  Pick<TypographyOwnProps, 'as'>;

const useStyles = makeStyles<CardProps>()({
  root: (props) => [props.className],
  title: ['my-2 mx-4'],
  container: (props) => [props.containerClassName]
});

export const Card: React.FC<CardProps> = React.memo(
  ({
    children,
    title,
    containerClassName,
    className,
    as = 'h2' as Pick<TypographyOwnProps, 'as'>['as'],
    ...props
  }) => {
    const styles = useStyles({ title, className, containerClassName });

    return (
      <section>
        <PanelBasic flex column spacing="MD" className={className} {...props}>
          {title && (
            <MetaText bold className={styles.title} as={as}>
              {title}
            </MetaText>
          )}
          <div className={styles.container}>{children}</div>
        </PanelBasic>
      </section>
    );
  }
);

Card.displayName = 'Card';

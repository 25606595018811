import { SelectGroupType } from 'components/common/Input/SelectInputGroup/RadioGroup';
import { PanelCommonStyles } from 'components/layout/Panel/PanelFlex';
import { getInteraction, makeStyles } from 'lib/makeStyles';
import React from 'react';
import {
  PanelAsProp,
  PanelPaddingProps,
  PanelRelativeProp,
  PanelSizeProps,
  usePanelPaddingStyles,
  usePanelRelativeStyles,
  usePanelRoundedStyles,
  usePanelShadowStyles,
  usePanelSizeStyles
} from 'styles/panelStyles';
import { useBackgroundVariantStyles, useBorderVariantStyles } from 'styles/variantStyles';

export type PanelProps = {
  enableBackground?: boolean;
  enableBorder?: boolean;
  rounded?: true | false | 'SM' | 'MD' | 'LG' | 'full';
  shadow?: false | true | 'SM' | 'MD' | 'LG';
} & ActiveProp &
  DisabledProp &
  HoverProp &
  IntentProp &
  Pick<SelectGroupType, 'hasSelectedSticky'> &
  ChildrenProp &
  ParentClassNameProp &
  PanelAsProp &
  PanelSizeProps &
  PanelPaddingProps &
  PanelRelativeProp &
  DarkProp;

export type PanelStyleProps = {
  backgroundStyles?: string;
  borderStyles?: string;
  roundedStyles?: string;
  shadowStyles?: string;
} & PanelCommonStyles;

const useStyles = makeStyles<PanelProps & PanelStyleProps>()({
  root: (props) => [
    {
      'sticky top-[-4px] z-10': props.hasSelectedSticky && props.active
    },
    props.className,
    props.paddingStyles,
    props.relativeStyles,
    props.sizeStyles,
    Boolean(props.enableBackground) && props.backgroundStyles,
    Boolean(props.enableBorder) && props.borderStyles,
    Boolean(props.rounded) && props.roundedStyles,
    Boolean(props.shadow) && props.shadowStyles
  ]
});

export const Panel: React.FC<PanelProps> = React.memo((props: PanelProps) => {
  const { active, as, children, enableHover, disabled, hasSelectedSticky, intent, dark } = props;
  const paddingStyles = usePanelPaddingStyles(props);
  const relativeStyles = usePanelRelativeStyles(props);
  const roundedStyles = usePanelRoundedStyles(props);
  const shadowStyles = usePanelShadowStyles(props);
  const sizeStyles = usePanelSizeStyles(props);

  const backgroundStyles = useBackgroundVariantStyles({
    enableHover
  })({
    intent: intent,
    interaction: getInteraction({ checked: active, disabled }),
    mode: dark ? 'dark' : 'default',
    prominence: 'default'
  });

  const borderStyles = useBorderVariantStyles({
    enableHover
  })({
    intent: intent,
    interaction: getInteraction({ checked: active, disabled }),
    mode: dark ? 'dark' : 'default',
    prominence: 'default'
  });

  const styles = useStyles({
    backgroundStyles: backgroundStyles,
    borderStyles: borderStyles,
    hasSelectedSticky,
    paddingStyles: paddingStyles.padding,
    relativeStyles: relativeStyles.relative,
    roundedStyles: roundedStyles.rounded,
    shadowStyles: shadowStyles.shadow,
    sizeStyles: sizeStyles.size,
    ...props
  });

  const WrapperElement = as;

  return <WrapperElement className={styles.root}>{children}</WrapperElement>;
});

Panel.defaultProps = {
  active: false,
  as: 'div',
  dark: false,
  disabled: false,
  enableBackground: false,
  enableBorder: false,
  enableHover: false,
  hasSelectedSticky: false,
  intent: 'neutral',
  padding: false,
  paddingX: 'MD',
  paddingY: 'SM',
  relative: false,
  rounded: false,
  shadow: false
};

Panel.displayName = 'Panel';

import { Panel, PanelProps } from 'components/layout/Panel/Panel';
import { PanelFlex, PanelFlexProps } from 'components/layout/Panel/PanelFlex';
import React from 'react';

export type PanelItemProps = PanelFlexProps & PanelProps;

export const PanelItem: React.FC<PanelItemProps> = React.memo((props: PanelItemProps) => {
  const {
    active,
    as,
    children,
    className,
    enableBackground,
    enableBorder,
    enableHover,
    fullHeight,
    fullWidth,
    disabled,
    hasSelectedSticky,
    intent,
    relative,
    rounded,
    shadow,
    ...panelFlexOwnProps
  } = props;
  return (
    <Panel
      active={active}
      as={as}
      className={className}
      disabled={disabled}
      enableBackground={enableBackground}
      enableBorder={enableBorder}
      enableHover={enableHover}
      fullHeight={fullHeight}
      fullWidth={fullWidth}
      hasSelectedSticky={hasSelectedSticky}
      intent={intent}
      relative={relative}
      rounded={rounded}
      shadow={shadow}>
      <PanelFlex fullWidth fullHeight {...panelFlexOwnProps}>
        {children}
      </PanelFlex>
    </Panel>
  );
});

PanelItem.defaultProps = {
  active: false,
  disabled: false,
  enableBackground: false,
  enableBorder: false,
  enableHover: false,
  hasSelectedSticky: false,
  intent: 'neutral',
  relative: false,
  rounded: false,
  shadow: false
};

PanelItem.displayName = 'PanelItem';

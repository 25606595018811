import { useTranslation } from 'hooks/useContentful/useTranslation';
import { useCustomKeyDown } from 'hooks/useKeyboard';
import { CloseCircleIcon } from 'icons/monochrome/CloseCircleIcon';
import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';

import { CloseButtonProps } from './CloseButton';

export type CloseCircleButtonProps = Pick<CloseButtonProps, 'onClose'> & ParentClassNameProp;

const useStyles = makeStyles<Omit<CloseCircleButtonProps, 'onClose'>>()({
  root: (props) => props.className,
  icon: ['text-xs hover:text-secondaryIconHover text-secondaryIcon']
});

export const CloseCircleButton: React.FC<CloseCircleButtonProps> = memo(
  (props: CloseCircleButtonProps) => {
    const { onClose } = props;
    const styles = useStyles(props);
    const { t } = useTranslation();

    const onClickCloseIcon = React.useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (e: any) => {
        e?.preventDefault();
        return onClose(e);
      },
      [onClose]
    );

    const handleCustomKeyDown = useCustomKeyDown({
      onEnter: onClickCloseIcon,
      onSpace: onClickCloseIcon
    });

    return (
      <div
        role="button"
        title={t('CLOSE')}
        aria-label={t('CLOSE')}
        tabIndex={0}
        className={styles.root}
        onClick={onClickCloseIcon}
        onKeyDown={handleCustomKeyDown}>
        <CloseCircleIcon className={styles.icon} />
      </div>
    );
  }
);

CloseCircleButton.displayName = 'CloseCircleButton';

import { SmallText } from 'components/common/Typography';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';

const useStyles = makeStyles({
  text: ['pt-2 pb-1 font-medium']
});

type SeeMoreButtonProps = {
  handleClick: GenericHandler;
};

export const SeeMoreButton = memo(({ handleClick }: SeeMoreButtonProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <button onClick={handleClick}>
      <SmallText enableHover className={styles.text} whitespace="nowrap">
        {t('SEE_MORE_BUTTON')}
      </SmallText>
    </button>
  );
});

SeeMoreButton.displayName = 'SeeMoreButton';

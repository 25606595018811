import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const InfoIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={17} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4.5C1 2.84315 2.34315 1.5 4 1.5H12C13.6569 1.5 15 2.84315 15 4.5V12.5C15 14.1569 13.6569 15.5 12 15.5H4C2.34315 15.5 1 14.1569 1 12.5V4.5ZM8 8.5C8.55228 8.5 9 8.94772 9 9.5V12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12V9.5C7 8.94772 7.44772 8.5 8 8.5ZM8 6.5C8.55228 6.5 9 6.05228 9 5.5C9 4.94772 8.55228 4.5 8 4.5C7.44772 4.5 7 4.94772 7 5.5C7 6.05228 7.44772 6.5 8 6.5Z"
      fill="currentColor"
    />
  </Glyph>
));

InfoIcon.displayName = 'InfoIcon';

export { InfoIcon };

import { useToggle } from 'hooks/useToggle';
import { loadScript } from 'lib/loadScript';
import { useCallback, useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';
import { truckMapConfig } from 'truckMapConfig';

export type Props = {
  minScrollY?: number;
  scrollTop?: number;
};

let polyfillLoaded = false;

const useScrollButton = ({ minScrollY: customMinScrollY, scrollTop }: Props) => {
  const [minScrollY] = useState<number>(customMinScrollY ?? 400);
  const { status: showButton, enable: setShowButton, disable: setHideButton } = useToggle(false);
  const { y: scrollY } = useWindowScroll();

  useEffect(() => {
    if (!truckMapConfig.isSmoothScrollSupported && !polyfillLoaded) {
      loadScript(`${truckMapConfig.staticCDN}/assets/js/polyfill/smoothscroll.js`).then(() => {
        polyfillLoaded = true;
      });
    }
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: scrollTop ?? 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      scrollY > minScrollY ? setShowButton() : setHideButton();
    }
  }, [scrollY]);

  return {
    scrollToTop,
    showButton
  };
};

export { useScrollButton };

import { memo } from 'react';
import { truckMapConfig } from 'truckMapConfig';

export const ExternalLink: React.ComponentType<React.AnchorHTMLAttributes<HTMLAnchorElement>> =
  memo(({ children, ...props }) => (
    <a
      target={!truckMapConfig.isTenstreet ? '_blank' : '_self'}
      {...props}
      rel="noopener noreferrer">
      {children}
    </a>
  ));

ExternalLink.displayName = 'ExternalLink';

import { TransitionClasses } from '@headlessui/react';
import cx from 'clsx';

export const transformGPUEnterTransition: TransitionClasses = {
  enter: 'transform-gpu',
  enterFrom: 'transform-gpu',
  enterTo: 'transform-gpu'
};
export const transformGPULeaveTransition: TransitionClasses = {
  leave: 'transform-gpu',
  leaveFrom: 'transform-gpu',
  leaveTo: 'transform-gpu'
};

export const shadowTransition: TransitionClasses = {
  ...transformGPUEnterTransition,
  ...transformGPULeaveTransition
};

export const transformGPUShadowTransition: TransitionClasses = {
  ...shadowTransition,
  enter: cx(shadowTransition.enter, 'transition-shadow'),
  leave: cx(shadowTransition.leave, 'transition-shadow')
};

import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const UserCheckIcon = React.memo((props: GlyphProps) => (
  <Glyph width={24} height={24} {...props}>
    <circle
      cx="12"
      cy="8"
      r="3.25"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12.25 19.25H6.94953C5.77004 19.25 4.88989 18.2103 5.49085 17.1954C6.36247 15.7234 8.23935 14 12.25 14"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M14.75 17.75L16 19.25L19.25 14.75"
    />
  </Glyph>
));

UserCheckIcon.displayName = 'UserCheckIcon';

export { UserCheckIcon };

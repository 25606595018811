import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const CalendarPlusIcon = React.memo((props: GlyphProps) => (
  <Glyph width={24} height={24} {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.25 11.25V8.75C19.25 7.64543 18.3546 6.75 17.25 6.75H6.75C5.64543 6.75 4.75 7.64543 4.75 8.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H11.25M17 14.75V19.25M19.25 17H14.75M8 4.75V8.25M16 4.75V8.25M7.75 10.75H16.25"
    />
  </Glyph>
));

CalendarPlusIcon.displayName = 'CalendarPlusIcon';

export { CalendarPlusIcon };

import { TransitionClasses } from '@headlessui/react';
import cx from 'clsx';

import {
  opacity100EaseTransition,
  opacity100EnterTransition,
  opacity100LeaveTransition,
  opacity100ScaleTransition,
  opacityEaseInOutTransition
} from './opacityTransition';
import { transformGPUShadowTransition } from './shadowTransition';

export const tooltipOpacityTransition: TransitionClasses = {
  ...opacity100EnterTransition,
  enter: cx(opacity100EnterTransition.enter, 'ease-in duration-100')
};

export const commonOpacityTransition: TransitionClasses = {
  ...opacity100EaseTransition,
  enter: cx(opacityEaseInOutTransition.enter, 'duration-100'),
  leave: cx(opacityEaseInOutTransition.leave, 'duration-75')
};

export const modalOverlayTransition: TransitionClasses = {
  ...commonOpacityTransition,
  leave: cx(opacity100ScaleTransition.leave, 'delay-75')
};

export const modalContentTransition: TransitionClasses = {
  ...opacity100ScaleTransition,
  enter: cx(opacity100ScaleTransition.enter, 'delay-100')
};

export const imageOpacityTransition = (isAnimating: boolean): TransitionClasses => ({
  ...opacity100EnterTransition,
  enter: cx(opacity100EnterTransition.enter, 'ease-in', {
    'duration-10': isAnimating,
    'duration-0': !isAnimating
  })
});

export const selectListboxTransition: TransitionClasses = {
  ...opacity100LeaveTransition,
  leave: cx(opacity100LeaveTransition.leave, 'duration-10 ease-in')
};

export const highlightShareTransition = (isAnimating: boolean): TransitionClasses => ({
  ...opacity100EnterTransition,
  ...opacity100LeaveTransition,
  enter: cx('ease-in-out', opacity100EnterTransition.enter, {
    'duration-10': isAnimating,
    'duration-0': !isAnimating
  }),
  leave: cx('ease-in-out', opacity100EnterTransition.leave, {
    'duration-10': isAnimating,
    'duration-0': !isAnimating
  })
});

export const rightToLeftTransition = {
  enter: 'delay-400 transition-all ease-in-out duration-[400ms]',
  enterFrom: 'translate-x-full',
  enterTo: 'translate-x-0',
  leave: 'duration-[400ms] transition-all ease-in-out',
  leaveFrom: 'translate-x-0',
  leaveTo: 'translate-x-full'
};

export { transformGPUShadowTransition };

export const transitionAllEaseInOut200 = 'transition-all ease-in-out duration-200';

export const height50EaseIn150 = 'min-h-[50px] max-h-[50px] ease-in duration-150';
export const height0EaseOut100 = 'min-h-[0px] max-h-[0px] ease-out duration-100';
export const opacityEaseIn150 = 'opacity-100 ease-in duration-150 delay-75';
export const opacityEaseOut100 = 'opacity-0 ease-out duration-100';
export const opacityEaseOut050 = 'opacity-0 ease-out delay-50 duration-50';
export const easeInOut150 = 'ease-in-out duration-150';
export const easeIn150 = 'ease-in duration-150';
export const easeOut150 = 'ease-out duration-150';

export const mapIconTransition = {
  transition: {
    duration: 9000,
    delay: 8000
  }
};

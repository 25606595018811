import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import {
  PanelAsProp,
  PanelPaddingProps,
  PanelRelativeProp,
  PanelSizeProps,
  usePanelDividerStyles,
  usePanelFlexStyles,
  usePanelPaddingStyles,
  usePanelRelativeStyles,
  usePanelSizeStyles,
  usePanelSpacingStyles
} from 'styles/panelStyles';

export type PanelFlexProps = {
  align?: 'start' | 'center' | 'end' | 'stretch';
  column?: boolean;
  disableFlex?: boolean;
  reverse?: boolean;
  justify?: 'between' | 'start' | 'center' | 'end' | 'around' | 'evenly' | 'stretch' | 'normal';
  row?: boolean;
  showDivider?: boolean;
  spacing?: false | 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | '2XL';
  wrap?: true | 'nowrap' | 'wrap' | 'wrap-reverse';
} & ChildrenProp &
  ParentClassNameProp &
  PanelAsProp &
  PanelSizeProps &
  PanelPaddingProps &
  PanelRelativeProp &
  Pick<React.JSX.IntrinsicElements['div'], 'style'>;

export type PanelFlexStyleProps = {
  dividerStyles?: string;
  flexStyles?: string;
  spacingStyles?: string;
} & PanelCommonStyles;

export type PanelCommonStyles = {
  relativeStyles?: string;
  paddingStyles?: string;
  sizeStyles?: string;
};

const useStyles = makeStyles<PanelFlexProps & PanelFlexStyleProps>()({
  root: (props) => [
    props.className,
    props.dividerStyles,
    !props.disableFlex && props.flexStyles,
    props.paddingStyles,
    props.relativeStyles,
    props.sizeStyles,
    !props.showDivider && props.spacingStyles
  ]
});

export const PanelFlex: React.FC<PanelFlexProps> = React.memo(({ style, children, ...props }) => {
  const { as } = props;
  const dividerStyles = usePanelDividerStyles(props);
  const flexStyles = usePanelFlexStyles(props);
  const paddingStyles = usePanelPaddingStyles(props);
  const relativeStyles = usePanelRelativeStyles(props);
  const sizeStyles = usePanelSizeStyles(props);
  const spacingStyles = usePanelSpacingStyles(props);

  const styles = useStyles({
    dividerStyles: dividerStyles.divider,
    flexStyles: flexStyles.flex,
    paddingStyles: paddingStyles.padding,
    relativeStyles: relativeStyles.relative,
    sizeStyles: sizeStyles.size,
    spacingStyles: spacingStyles.spacing,
    ...props
  });

  const WrapperElement = as;

  return (
    <WrapperElement style={style} className={styles.root} draggable>
      {children}
    </WrapperElement>
  );
});

PanelFlex.defaultProps = {
  align: 'center',
  as: 'div',
  column: false,
  disableFlex: false,
  fullHeight: false,
  fullWidth: false,
  justify: 'start',
  padding: true,
  paddingX: false,
  paddingY: 'MD',
  relative: false,
  row: true,
  showDivider: false,
  spacing: 'SM',
  wrap: 'nowrap',
  reverse: false
};

PanelFlex.displayName = 'PanelFlex';

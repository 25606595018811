import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const MapIcon = React.memo((props: GlyphProps) => (
  <Glyph width={24} height={24} {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4.75 6.75L9.25 4.75V17.25L4.75 19.25V6.75Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M14.75 6.75L19.25 4.75V17.25L14.75 19.25V6.75Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M14.75 6.75L9.25 4.75V17.25L14.75 19.25V6.75Z"
    />
  </Glyph>
));

MapIcon.displayName = 'MapIcon';

export { MapIcon };

import { Translation } from 'components/common/Translation';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { ChevronIcon } from 'icons/monochrome/ChevronIcon';
import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';

export type ShowHideButtonProps = {
  show?: boolean;
  handleClick?: GenericHandler;
} & JSX.IntrinsicElements['button'];

const useStyles = makeStyles<ShowHideButtonProps>()({
  button: [
    'rounded-full',
    'my-4',
    'bg-white hover:bg-neutralBackground',
    'border border-primaryBorder hover:border-primaryBorderHover',
    'text-smallMobile'
  ],
  icon: (props) => [!props?.show ? '-rotate-90' : 'rotate-90']
});

const ShowHideButton = memo(({ show = false, handleClick, ...props }: ShowHideButtonProps) => {
  const styles = useStyles({ show });

  return (
    <button className={styles.button} onClick={handleClick} {...props}>
      <PanelItem paddingX="SM" paddingY="SM">
        {!show ? <Translation textKey="HIDE" /> : <Translation textKey="SHOW" />}
        <ChevronIcon className={!show ? '-rotate-90' : 'rotate-90'} />
      </PanelItem>
    </button>
  );
});

ShowHideButton.displayName = 'RouteTabsButton';

export { ShowHideButton };

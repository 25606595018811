import { useTranslation } from 'hooks/useContentful/useTranslation';
import { DotsVerticalIcon } from 'icons/monochrome/DotsVerticalIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { HeroIcon } from '../HeroIcon';

export type VerticalDotsButtonProps = {
  as?: 'button' | 'div';
  hasBackground?: boolean;
} & ClickEventProp &
  ParentClassNameProp;

const useStyles = makeStyles<VerticalDotsButtonProps>()({
  root: (props) => [
    'block',
    'w-min',
    'py-1',
    'group',
    {
      'bg-neutralBackgroundSoft border-primaryBorder': props.hasBackground,
      'bg-transparent border-transparent': !props.hasBackground
    },
    'hover:bg-primaryBackground',
    'border hover:border-primaryBorderHover',
    'rounded-md',
    'filter hover:shadow-sm',
    'cursor-pointer',
    props.className
  ],
  // 1px margin to match 40px height of Input
  icon: ['text-lg my-[1px] text-primaryIcon group-hover:text-primaryIconHover']
});

const DotsVerticalButton = React.forwardRef<HTMLDivElement, VerticalDotsButtonProps>(
  ({ onClick, as = 'button', ...props }, ref) => {
    const styles = useStyles(props);
    const { t } = useTranslation();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (e?: any) => {
      e.preventDefault();
      onClick && onClick(e);
      return;
    };

    if (as !== 'button') {
      return (
        <div ref={ref} className={styles.root}>
          <HeroIcon type="solid" name="ellipsis-vertical" className={styles.icon} />
        </div>
      );
    }

    return (
      <div
        ref={ref}
        role="button"
        title={t('ARIA_POPOUT_MENU')}
        aria-label={t('ARIA_POPOUT_MENU')}
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
        className={styles.root}>
        <DotsVerticalIcon className={styles.icon} />
      </div>
    );
  }
);

DotsVerticalButton.defaultProps = {
  hasBackground: false
};

DotsVerticalButton.displayName = 'DotsVerticalButton';

export { DotsVerticalButton };

import { makeStyles } from 'lib/makeStyles';
import {
  height0EaseOut100,
  height50EaseIn150,
  opacityEaseIn150,
  opacityEaseOut100
} from 'lib/transitionProps';

export const useFlexColumnLayoutStyles = makeStyles<{
  disableYSpacing?: boolean;
  containerClassName?: string;
  flexGrowClassName?: string;
  flexShrinkClassName?: string;
}>()({
  flexContainer: (props) => [
    'flex flex-col flex-nowrap',
    'h-full w-full',
    {
      'space-y-2': !props?.disableYSpacing
    },
    props?.containerClassName
  ],
  flexGrow: (props) => ['grow', 'w-full', 'overflow-y-auto', props?.flexGrowClassName],
  flexShrink: (props) => ['shrink-0', 'w-full', props?.flexShrinkClassName]
});

export const useFlexRowLayoutStyles = makeStyles<{
  disableXSpacing?: boolean;
  containerClassName?: string;
  flexGrowClassName?: string;
  flexShrinkClassName?: string;
}>()({
  flexContainer: (props) => [
    'flex flex-row flex-nowrap',
    'h-full w-full',
    {
      'space-x-2': !props?.disableXSpacing
    },
    props?.containerClassName
  ],
  flexGrow: (props) => ['grow', 'h-full', 'overflow-x-hidden', props?.flexGrowClassName],
  flexShrink: (props) => ['shrink-0', 'h-full', props?.flexShrinkClassName]
});

// overlaps two items using grid
export const useGridOverlapStyles = makeStyles<ParentClassNameProp>()({
  grid: (props) => ['grid', 'grid-cols-1 grid-rows-1', props?.className],
  gridItem: ['col-span-1 col-start-1', 'row-span-1 row-start-1']
});

export const useItemHeightTransitions = makeStyles<
  VisibilityProp & { heightEaseInTransition?: string }
>()({
  transitionContainer: () => ['block transition-all ease-in-out overflow-hidden'],
  sortableItem: (props) => [
    {
      [props?.heightEaseInTransition]: props?.show,
      [height0EaseOut100]: !props?.show
    }
  ],
  sortableItemInner: (props) => [
    {
      [opacityEaseIn150]: props?.show,
      [opacityEaseOut100]: !props?.show
    }
  ]
});

export const useSortableItemTransitions = (props?: VisibilityProp) =>
  useItemHeightTransitions({
    show: Boolean(props?.show),
    heightEaseInTransition: height50EaseIn150
  });

export type AbsolutePositionProps = {
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
};

export const useAbsolutePositionStyles = makeStyles<AbsolutePositionProps>()({
  absolutePosition: ({ top, right, bottom, left }) => [
    'absolute',
    top && 'top-0',
    right && 'right-0',
    bottom && 'bottom-0',
    left && 'left-0'
  ]
});

import { Transition } from '@headlessui/react';
import { opacity100Transition } from 'lib/transitionProps/opacityTransition';
import React from 'react';

type Props = {
  show?: boolean;
  className?: string;
} & ChildrenProp &
  Pick<React.HTMLAttributes<HTMLDivElement>, 'style'>;

export const OpacityTransition = React.forwardRef<HTMLDivElement, Props>(
  ({ children, show, className, style }: Props, ref) => {
    return (
      <Transition
        ref={ref}
        style={style}
        className={className}
        show={show}
        {...opacity100Transition}>
        {children}
      </Transition>
    );
  }
);

OpacityTransition.displayName = 'OpacityTransition';

const curlyBracesPattern = /{{.*?}}/g;
const clearCurlyBraces = /[{}]/g;

export function mustache(template: string, variables: Record<string, string>) {
  if (template.match(curlyBracesPattern)) {
    return template.replace(
      curlyBracesPattern,
      (match) => variables[match.replace(clearCurlyBraces, '')]
    );
  }

  return template;
}

import { useCallback } from 'react';

import { useCustomKeyDown } from '../useKeyboard';

type CustomNextElementProps = {
  nextElement: HTMLElement;
  callback?: GenericHandler;
  name?: string;
};

type HandleTabProps = {
  isValid: boolean;
} & GenericEvent;

/**
 * Focuses on custom element
 * @param nextElement HTMLElement
 * @param callback () => void
 * @returns object handleCustomKeyDown
 */
export const useCustomNextElement = ({ nextElement, callback }: CustomNextElementProps) => {
  const handleTab = useCallback(
    ({ isValid, e }: HandleTabProps) => {
      if (isValid) {
        nextElement.focus();
        return callback && callback(e);
      }
    },
    [nextElement, callback]
  );

  const handleKeydownTab = (isValid: boolean) => {
    return useCustomKeyDown({
      onTab: (e) => handleTab({ isValid, e })
    });
  };

  return {
    handleKeydownTab: (isValid: boolean) => handleKeydownTab(isValid)
  };
};

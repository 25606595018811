import { CheckboxIcon } from 'icons/monochrome/CheckboxIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export type Props = {
  type?: 'checkbox' | 'radio';
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
};

export const useContainerStyles = makeStyles<Props>()({
  container: (props) => [
    'relative w-5 h-5',
    'flex justify-center items-center',
    'shrink-0',
    // offset vertical center
    'mt-[2px]',
    'cursor-pointer',
    {
      'rounded-md border': props.type === 'checkbox',
      'rounded-xl border': props.type === 'radio'
    },
    {
      'drop-shadow-sm hover:drop-shadow group-hover:drop-shadow': !props.disabled
    },
    {
      // if indeterminate, checked == true
      'bg-tmBlue border-primaryBorderActive': props.checked && !props.disabled,
      'bg-white border-primaryBorder hover:border-primaryBorderHover':
        !props.checked && !props.disabled,
      'bg-white border-primaryBorderDisabled': !props.checked && props.disabled,
      'bg-blue-100 border-blue-100': props.checked && props.disabled
    }
  ],
  iconWrapper: ['absolute']
});

export const useStyles = makeStyles<Props>()({
  checkIcon: (props) => [
    {
      'block w-2.5 h-2.5 text-white': props.checked && !props.indeterminate,
      hidden: !props.checked || props.indeterminate
    }
  ],
  minusIcon: (props) => [
    {
      'block w-2.5 h-1 rounded-md bg-white': props.checked && props.indeterminate,
      hidden: !props.indeterminate && !props.checked
    }
  ]
});

export const Checkbox = React.memo(
  ({ type = 'checkbox', disabled = false, indeterminate = false, checked = false }: Props) => {
    const styles = useStyles({ type, checked, disabled, indeterminate });
    const containerStyles = useContainerStyles({ type, checked, disabled, indeterminate });

    return (
      <div className={containerStyles.container}>
        <div className={containerStyles.iconWrapper}>
          <CheckboxIcon className={styles.checkIcon} />
          <div className={styles.minusIcon} />
        </div>
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';

import { calculateBreakpoints } from 'lib/calculateBreakpoints';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';
import { createBreakpoint } from 'react-use';
import { truckMapConfig } from 'truckMapConfig';

const truckMapBreakpoints = truckMapConfig.responsiveBreakpoints;

/**
 * Calculates breakpoints
 * @param breakpointUp: BreakpointProp
 * @param breakpointDown: BreakpointProp
 * @returns { breakpoints: breakpointsEnum, currentBreakpoint: string, }
 */

export enum breakpointsEnum {
  'XS' = 'xs',
  'SM' = 'sm',
  'MD' = 'md',
  'LG' = 'lg',
  'XL' = 'xl',
  'XXL' = '2xl'
}

export type Breakpoint = 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | 'XXL';
export type BreakpointProp = Breakpoint | false;

export const useBreakpoints = (breakpointUp?: BreakpointProp, breakpointDown?: BreakpointProp) => {
  const useBreakpoint = createBreakpoint(truckMapBreakpoints);
  const currentBreakpoint = useBreakpoint();

  const { breakpointsUp, breakpointsDown } = useMemo(
    () =>
      calculateBreakpoints({
        breakpointUp,
        breakpointDown
      }),
    [breakpointUp, breakpointDown]
  );

  const show: boolean = useMemo(
    () =>
      Boolean(
        uniq([...breakpointsUp, ...breakpointsDown]).find(
          (key) => breakpointsEnum[`${key}`] === currentBreakpoint
        )?.length
      ),
    [currentBreakpoint, breakpointsUp, breakpointsDown]
  );

  return useMemo(() => {
    return {
      currentBreakpoint,
      breakpoints: breakpointsEnum,
      breakpointsUp,
      breakpointsDown,
      show
    };
  }, [currentBreakpoint, breakpointsUp, breakpointsDown, show]);
};

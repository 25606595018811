import { truckMapConfig } from 'truckMapConfig';

export async function loadScript(source: string) {
  return new Promise((resolve) => {
    if (!truckMapConfig.isClientSideRender) {
      resolve(false);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let script = document.createElement('script') as any;
    const prior = document.getElementsByTagName('script')[0];

    script.async = true;
    script.onload = script.onreadystatechange = function (_, isAbort) {
      if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
        script.onload = script.onreadystatechange = null;
        script = undefined;

        if (!isAbort) {
          resolve(script);
        }
      }
    };
    script.src = source;
    prior.parentNode.insertBefore(script, prior);
  });
}

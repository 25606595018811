import { EnableHoverType } from 'components/common/Typography';
import { makeVariantStyles } from 'lib/makeStyles';
import { useMemo } from 'react';
import { VariantStylesConfig } from 'styles/variantStyles/variantStylesConfig';

const getPrimaryBorderHover = (enableHover: EnableHoverType) =>
  enableHover === 'group'
    ? 'group-hover:border-primaryBorderHover'
    : 'hover:border-primaryBorderHover';

const getPrimaryBorderDarkHover = (enableHover: EnableHoverType) =>
  enableHover === 'group'
    ? 'group-hover:border-primaryBorderDarkHover'
    : 'hover:border-primaryBorderDarkHover';

const getSecondaryBorderHover = (enableHover: EnableHoverType) =>
  enableHover === 'group'
    ? 'group-hover:border-secondaryBorderHover'
    : 'hover:border-secondaryBorderHover';

const getSecondaryBorderDarkHover = (enableHover: EnableHoverType) =>
  enableHover === 'group'
    ? 'group-hover:border-secondaryBorderDarkHover'
    : 'hover:border-secondaryBorderDarkHover';

export const makeBorderVariantStyles = ({ enableHover, baseClassNames }: VariantStylesConfig) =>
  makeVariantStyles(
    {
      compoundVariants: [
        {
          intent: 'primary',
          mode: 'default',
          interaction: 'hover',
          class: [
            'border-primaryBorder',
            Boolean(enableHover) && getPrimaryBorderHover(enableHover)
          ]
        },
        {
          intent: 'primary',
          mode: 'default',
          interaction: 'active',
          class: ['border-primaryBorderActive']
        },
        {
          intent: 'primary',
          mode: 'default',
          interaction: 'disabled',
          class: ['border-primaryBorderDisabled']
        },
        {
          intent: 'primary',
          mode: 'dark',
          interaction: 'hover',
          class: [Boolean(enableHover) && getPrimaryBorderDarkHover(enableHover)]
        },
        {
          intent: 'primary',
          mode: 'dark',
          interaction: 'active',
          class: ['border-primaryBorderDarkActive']
        },
        {
          intent: 'primary',
          mode: 'dark',
          interaction: 'disabled',
          class: ['primaryBorderDarkDisabled']
        },
        {
          intent: 'secondary',
          mode: 'default',
          interaction: 'hover',
          class: [
            'border-secondaryBorder',
            Boolean(enableHover) && getSecondaryBorderHover(enableHover)
          ]
        },
        {
          intent: 'secondary',
          mode: 'default',
          interaction: 'active',
          class: ['border-secondaryBorderActive']
        },
        {
          intent: 'secondary',
          mode: 'default',
          interaction: 'disabled',
          class: ['border-secondaryBorderDisabled']
        },
        {
          intent: 'secondary',
          mode: 'dark',
          interaction: 'hover',
          class: [Boolean(enableHover) && getSecondaryBorderDarkHover(enableHover)]
        },
        {
          intent: 'secondary',
          mode: 'dark',
          interaction: 'active',
          class: ['border-secondaryBorderDarkActive']
        },
        {
          intent: 'secondary',
          mode: 'dark',
          interaction: 'disabled',
          class: ['border-secondaryBorderDarkDisabled']
        },
        {
          intent: 'success',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: ['border-successBorder', Boolean(enableHover) && 'hover:border-successBorderHover']
        },
        {
          intent: 'success',
          mode: 'default',
          interaction: 'hover',
          prominence: 'loud',
          class: ['border-successBorder', Boolean(enableHover) && 'hover:border-successBorderHover']
        },
        {
          intent: 'danger',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: ['border-dangerBorder', Boolean(enableHover) && 'hover:border-dangerBorderHover']
        },
        {
          intent: 'danger',
          mode: 'default',
          interaction: 'hover',
          prominence: 'loud',
          class: ['border-dangerBorder', Boolean(enableHover) && 'hover:border-dangerBorderHover']
        },
        {
          intent: 'info',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: ['border-infoBorder', Boolean(enableHover) && ' hover:border-infoBorderHover']
        },
        {
          intent: 'info',
          mode: 'default',
          interaction: 'hover',
          prominence: 'loud',
          class: ['border-infoBorder', Boolean(enableHover) && 'hover:border-infoBorderHover']
        }
      ]
    },
    baseClassNames ?? ['border']
  );

export const useBorderVariantStyles = ({
  enableHover,
  baseClassNames,
  whiteBackground
}: VariantStylesConfig) =>
  useMemo(
    () =>
      makeBorderVariantStyles({
        enableHover,
        baseClassNames,
        whiteBackground
      }),
    [enableHover, baseClassNames, whiteBackground]
  );

import { AlertTypes } from 'components/common/Alert/Alert';
import { DangerIcon } from 'icons/alert/DangerIcon';
import { InfoIcon } from 'icons/alert/InfoIcon';
import { SuccessIcon } from 'icons/alert/SuccessIcon';
import { useMemo } from 'react';

const useAlertIcon = (type: AlertTypes | string) => {
  return useMemo(() => {
    switch (type) {
      case 'danger':
        return <DangerIcon className="w-[18px] h-[18px] text-dangerIcon" />;
      case 'success':
        return <SuccessIcon className="w-[18px] h-[18px] text-successIcon" />;
      default:
        return <InfoIcon className="w-[18px] h-[18px] text-infoIcon" />;
    }
  }, [type]);
};

export { useAlertIcon };

import { ITripInstruction } from '@truckmap/common';
import { atom, atomFamily } from 'recoil';

type MapSettings = {
  truckRestrictions: {
    showSettings: boolean;
  };
  outOfBounds: boolean;
};

export const mapSettingsAtom = atomFamily<MapSettings, string>({
  key: 'mapSettings',
  default: {
    truckRestrictions: {
      showSettings: false
    },
    outOfBounds: false
  }
});

export const currentUserSettingsAtom = atom({
  key: 'currentUserSettings',
  default: {
    equipmentWeights: 0,
    equipmentHeights: 0
  }
});

export const activeDirectionsAtom = atomFamily<ITripInstruction | null, string>({
  key: 'activeDirections',
  default: null
});

export const mapInvisibleLayersAtom = atomFamily<string[], string>({
  key: 'mapInvisibleLayers',
  default: []
});

import { Transition } from '@headlessui/react';
import { transformGPUShadowTransition } from 'lib/transitionProps/transitionProps';
import React from 'react';

type Props = {
  open?: boolean;
} & ChildrenProp;

const PopoverOpenTransition: React.FC<Props> = ({ children, open }) => {
  return (
    <Transition show={open} {...transformGPUShadowTransition}>
      {children}
    </Transition>
  );
};

PopoverOpenTransition.defaultProps = {
  open: false
};

PopoverOpenTransition.displayName = 'PopoverOpenTransition';

export { PopoverOpenTransition };

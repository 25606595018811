import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const ClockIcon = React.memo((props: GlyphProps) => (
  <Glyph width={24} height={24} {...props}>
    <circle cx="12" cy="12" r="7.25" stroke="currentColor" strokeWidth="1.5" />
    <path stroke="currentColor" strokeWidth="1.5" d="M12 8V12L14 14" />
  </Glyph>
));

ClockIcon.displayName = 'ClockIcon';

export { ClockIcon };

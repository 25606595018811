import { EnableHoverType } from 'components/common/Typography';
import { makeVariantStyles } from 'lib/makeStyles';
import { useMemo } from 'react';
import { VariantStylesConfig } from 'styles/variantStyles/variantStylesConfig';

export const getPrimaryBackgroundHover = (enableHover: EnableHoverType) =>
  enableHover === 'group'
    ? 'group-hover:bg-primaryBackgroundHover'
    : 'hover:bg-primaryBackgroundHover';

const getPrimaryBackgroundDarkHover = (enableHover: EnableHoverType) =>
  enableHover === 'group'
    ? 'group-hover:bg-primaryBackgroundDarkHover'
    : 'hover:bg-primaryBackgroundDarkHover';

export const getSecondaryBackgroundHover = (enableHover: EnableHoverType) =>
  enableHover === 'group'
    ? 'group-hover:bg-secondaryBackgroundHover'
    : 'hover:bg-secondaryBackgroundHover';

const getSecondaryBackgroundDarkHover = (enableHover: EnableHoverType) =>
  enableHover === 'group'
    ? 'group-hover:bg-secondaryBackgroundDarkHover'
    : 'hover:bg-secondaryBackgroundDarkHover';

const getDefaultBackground = (whiteBackground: boolean, defaultClassName: string) =>
  whiteBackground ? 'bg-white' : defaultClassName;

export const makeBackgroundVariantStyles = ({
  enableHover,
  baseClassNames,
  whiteBackground
}: VariantStylesConfig) =>
  makeVariantStyles(
    {
      compoundVariants: [
        {
          intent: 'primary',
          mode: 'default',
          interaction: 'hover',
          class: [
            getDefaultBackground(whiteBackground, 'bg-primaryBackground'),
            Boolean(enableHover) && getPrimaryBackgroundHover(enableHover)
          ]
        },
        {
          intent: 'primary',
          mode: 'default',
          interaction: 'active',
          class: ['bg-primaryBackgroundActive']
        },
        {
          intent: 'primary',
          mode: 'default',
          interaction: 'disabled',
          class: ['bg-primaryBackgroundDisabled']
        },
        {
          intent: 'primary',
          mode: 'dark',
          interaction: 'hover',
          class: [
            getDefaultBackground(whiteBackground, 'bg-primaryBackgroundDark'),
            Boolean(enableHover) && getPrimaryBackgroundDarkHover(enableHover)
          ]
        },
        {
          intent: 'primary',
          mode: 'dark',
          interaction: 'active',
          class: ['bg-primaryBackgroundDarkActive']
        },
        {
          intent: 'primary',
          mode: 'dark',
          interaction: 'disabled',
          class: ['primaryBackgroundDarkDisabled']
        },
        {
          intent: 'secondary',
          mode: 'default',
          interaction: 'hover',
          class: [
            getDefaultBackground(whiteBackground, 'bg-secondaryBackground'),
            Boolean(enableHover) && getSecondaryBackgroundHover(enableHover)
          ]
        },
        {
          intent: 'secondary',
          mode: 'default',
          interaction: 'active',
          class: ['bg-secondaryBackgroundActive']
        },
        {
          intent: 'secondary',
          mode: 'default',
          interaction: 'disabled',
          class: ['bg-secondaryBackgroundDisabled']
        },
        {
          intent: 'secondary',
          mode: 'dark',
          interaction: 'hover',
          class: [
            getDefaultBackground(whiteBackground, 'bg-secondaryBackgroundDark'),
            Boolean(enableHover) && getSecondaryBackgroundDarkHover(enableHover)
          ]
        },
        {
          intent: 'secondary',
          mode: 'dark',
          interaction: 'active',
          class: ['bg-secondaryBackgroundDarkActive']
        },
        {
          intent: 'secondary',
          mode: 'dark',
          interaction: 'disabled',
          class: ['bg-secondaryBackgroundDarkDisabled']
        },
        {
          intent: 'success',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: [
            getDefaultBackground(whiteBackground, 'bg-successBackground'),
            Boolean(enableHover) && 'hover:bg-successBackgroundHover'
          ]
        },
        {
          intent: 'success',
          mode: 'default',
          interaction: 'hover',
          prominence: 'loud',
          class: [
            getDefaultBackground(whiteBackground, 'bg-successBackgroundLoud'),
            Boolean(enableHover) && 'hover:bg-successBackgroundLoudHover'
          ]
        },
        {
          intent: 'danger',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: [
            getDefaultBackground(whiteBackground, 'bg-dangerBackground'),
            Boolean(enableHover) && 'hover:bg-dangerBackgroundHover'
          ]
        },
        {
          intent: 'danger',
          mode: 'default',
          interaction: 'hover',
          prominence: 'loud',
          class: [
            getDefaultBackground(whiteBackground, 'bg-dangerBackgroundLoud'),
            Boolean(enableHover) && 'hover:hover:bg-dangerBackgroundLoudHover'
          ]
        },
        {
          intent: 'info',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: [
            getDefaultBackground(whiteBackground, 'bg-infoBackground'),
            Boolean(enableHover) && 'hover:bg-infoBackgroundHover'
          ]
        },
        {
          intent: 'info',
          mode: 'default',
          interaction: 'hover',
          prominence: 'loud',
          class: [
            getDefaultBackground(whiteBackground, 'bg-infoBackgroundLoud'),
            Boolean(enableHover) && 'hover:bg-infoBackgroundLoudHover'
          ]
        },
        {
          intent: 'neutral',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: [
            getDefaultBackground(whiteBackground, 'bg-neutralBackground'),
            Boolean(enableHover) && 'hover:bg-neutralBackgroundHover'
          ]
        }
      ]
    },
    baseClassNames
  );

export const useBackgroundVariantStyles = ({
  enableHover,
  baseClassNames,
  whiteBackground
}: VariantStylesConfig) =>
  useMemo(
    () => makeBackgroundVariantStyles({ enableHover, baseClassNames, whiteBackground }),
    [enableHover, baseClassNames, whiteBackground]
  );

import { NonPrefetchLink, NonPrefetchLinkProps } from 'components/NonPrefetchLink';
import React, { memo } from 'react';

import { BackButton } from './BackButton';

export const BackButtonLink: React.FC<NonPrefetchLinkProps> = memo((props) => {
  return (
    <NonPrefetchLink {...props}>
      <BackButton />
    </NonPrefetchLink>
  );
});

BackButtonLink.displayName = 'BackLinkButton';

import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const Dollar2Icon = React.memo((props: GlyphProps) => (
  <Glyph width={24} height={24} {...props}>
    <path
      d="M17.25 6.75H9.375C7.92525 6.75 6.75 7.92525 6.75 9.375C6.75 10.8247 7.92525 12 9.375 12H14.625C16.0747 12 17.25 13.1753 17.25 14.625C17.25 16.0747 16.0747 17.25 14.625 17.25H6.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.75 19.25V4.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Glyph>
));

Dollar2Icon.displayName = 'Dollar2Icon';

export { Dollar2Icon };

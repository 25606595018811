import { makeVariantStyles } from 'lib/makeStyles';

export const useButtonVariantStyles = makeVariantStyles(
  {
    compoundVariants: [
      {
        intent: 'primary',
        mode: 'default',
        interaction: 'hover',
        class: [
          'bg-tmBlue hover:bg-tmBlueHover',
          'border-primaryBorderActive hover:border-primaryBorderActiveHover'
        ]
      },
      {
        intent: 'primary',
        mode: 'default',
        interaction: 'disabled',
        class: ['bg-tmBlue/50', 'border-primaryBorderActive/50']
      },
      {
        intent: 'secondary',
        mode: 'default',
        interaction: 'hover',
        class: [
          'bg-primaryBackgroundDark hover:bg-primaryBackgroundDarkHover',
          'border-primaryBackgroundDark hover:border-primaryBackgroundDarkHover'
        ]
      },
      {
        intent: 'secondary',
        mode: 'default',
        interaction: 'disabled',
        class: ['bg-gray-400', 'border-gray-400']
      },
      {
        intent: 'neutral',
        mode: 'default',
        interaction: 'hover',
        class: ['bg-white', 'border-primaryBorder hover:border-primaryBorderHover']
      },
      {
        intent: 'neutral',
        mode: 'default',
        interaction: 'disabled',
        class: ['bg-neutralBackground', 'border-neutralBorder']
      },
      {
        intent: 'success',
        mode: 'default',
        interaction: 'hover',
        class: [
          'bg-successBackgroundLoud hover:bg-successBackgroundLoudHover',
          'border-successBorder hover:border-successBorderHover'
        ]
      },
      {
        intent: 'danger',
        mode: 'default',
        interaction: 'hover',
        class: [
          'bg-dangerBackgroundLoud hover:bg-dangerBackgroundLoudHover',
          'border-dangerBorder hover:border-dangerBorderHover'
        ]
      }
    ]
  },
  ['border']
);

import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const CompassIcon = React.memo((props: GlyphProps) => (
  <Glyph width={24} height={24} {...props}>
    <path
      d="M19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.409 10.409L15.2499 8.74997L13.591 13.591L8.75012 15.25L10.409 10.409Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Glyph>
));

CompassIcon.displayName = 'CompassIcon';

export { CompassIcon };

import { makeVariantStyles } from 'lib/makeStyles';
import { useMemo } from 'react';

import { VariantStylesConfig } from '../../../styles/variantStyles/variantStylesConfig';

export const makeAlertGradientVariantStyles = ({ baseClassNames }: VariantStylesConfig) =>
  makeVariantStyles(
    {
      compoundVariants: [
        {
          intent: 'success',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: ['to-successBackground']
        },
        {
          intent: 'success',
          mode: 'default',
          interaction: 'hover',
          prominence: 'loud',
          class: ['to-successBorder/30']
        },
        {
          intent: 'danger',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: ['to-dangerBackground']
        },
        {
          intent: 'danger',
          mode: 'default',
          interaction: 'hover',
          prominence: 'loud',
          class: ['to-dangerBorder/30']
        },
        {
          intent: 'info',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: ['to-infoBackground']
        },
        {
          intent: 'info',
          mode: 'default',
          interaction: 'hover',
          prominence: 'loud',
          class: ['to-infoBorder/30']
        }
      ]
    },
    baseClassNames ?? ['bg-gradient-to-tl', 'from-gray-010', 'via-gray-010']
  );

export const makeAlertAccentVariantStyles = ({ baseClassNames }: VariantStylesConfig) =>
  makeVariantStyles(
    {
      compoundVariants: [
        {
          intent: 'success',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: ['from-successBorder']
        },
        {
          intent: 'danger',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: ['from-dangerBorder']
        },
        {
          intent: 'info',
          mode: 'default',
          interaction: 'hover',
          prominence: 'default',
          class: ['from-infoBorder']
        }
      ]
    },
    baseClassNames ?? ['']
  );

export const useAlertAccentVariantStyles = ({ baseClassNames }: VariantStylesConfig) =>
  useMemo(() => makeAlertAccentVariantStyles({ baseClassNames }), [baseClassNames]);

export const useAlertGradientVariantStyles = ({ baseClassNames }: VariantStylesConfig) =>
  useMemo(() => makeAlertGradientVariantStyles({ baseClassNames }), [baseClassNames]);

import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const BackArrowIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.70711 2.29289C9.09763 2.68342 9.09763 3.31658 8.70711 3.70711L4.41421 8L8.70711 12.2929C9.09763 12.6834 9.09763 13.3166 8.70711 13.7071C8.31658 14.0976 7.68342 14.0976 7.29289 13.7071L2.29289 8.70711C1.90237 8.31658 1.90237 7.68342 2.29289 7.29289L7.29289 2.29289C7.68342 1.90237 8.31658 1.90237 8.70711 2.29289Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8C3 7.44772 3.44772 7 4 7H13C13.5523 7 14 7.44772 14 8C14 8.55228 13.5523 9 13 9H4C3.44772 9 3 8.55228 3 8Z"
      fill="currentColor"
    />
  </Glyph>
));

BackArrowIcon.displayName = 'BackArrowIcon';

export { BackArrowIcon };

import React from 'react';
import { KeyCodes } from 'types/common';

export const useKeyboardNavigation = ({
  ref,
  fallbackItem,
  escapeAction
}: {
  ref: React.MutableRefObject<HTMLDivElement | HTMLUListElement>;
  fallbackItem: string;
  escapeAction?: (e?: unknown) => void;
}) => {
  return (e) => {
    /*
        This searches for the next html element in order to tab navigate
        if there is no element, the navigation searches for the next section
        and starts navigate inside the new section
      */
    if (e.keyCode === KeyCodes.ESCAPE) {
      escapeAction?.();
    }

    if (e.keyCode === KeyCodes.TAB) {
      return e.shiftKey
        ? findPreviousElement(ref.current, fallbackItem)
        : findNextElement(ref.current);
    }
  };
};

const findNextElement = (element: HTMLElement) => {
  let nextElement = element.nextElementSibling as HTMLElement;

  if (!nextElement) {
    const parentElement = element.parentElement;
    const nextSection = parentElement.nextElementSibling;

    if (!nextSection) return;

    nextElement = nextSection.querySelector('[tabIndex="0"]') as HTMLElement;
  }

  if (nextElement.tabIndex === 0) return nextElement.focus();
};

const findPreviousElement = (element: HTMLElement, fallbackItem: string) => {
  let previousElement = element.previousElementSibling as HTMLElement;

  if (previousElement?.tabIndex !== 0) {
    const parentElement = element.parentElement;
    const previousSection = parentElement.previousElementSibling;

    if (!previousSection) {
      return (document.querySelector(fallbackItem) as HTMLElement).focus();
    }

    const allPreviousElements = previousSection.querySelectorAll('[tabIndex="0"]');
    previousElement = allPreviousElements[allPreviousElements.length - 1] as HTMLElement;
  }

  return previousElement.focus();
};

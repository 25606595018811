import { resolveHref } from 'next/dist/client/resolve-href';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { SyntheticEvent, useCallback, useMemo } from 'react';

export type NonPrefetchLinkProps = Omit<
  LinkProps,
  'prefetch' | 'passHref' | 'as' | 'replace' | 'legacyBehavior'
> &
  ChildrenProp &
  ParentClassNameProp &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target'>;

// avoid next/link to decide prefetching
export const NonPrefetchLink: React.ComponentType<NonPrefetchLinkProps> = React.memo(
  ({ children, href: url, shallow, scroll, locale, onClick, ...rest }) => {
    const router = useRouter();
    const href = useMemo(() => resolveHref(router, url, true)[1], [url, router]);
    const onClickHandler = useCallback(
      (e) => {
        (e as SyntheticEvent).preventDefault();
        onClick && onClick(e);
        router.push(href, href, { shallow, scroll, locale });
      },
      [router, href, onClick]
    );

    return (
      <a href={href} onClick={onClickHandler} {...rest}>
        {children}
      </a>
    );
  }
);

NonPrefetchLink.displayName = 'NonPrefetchLink';

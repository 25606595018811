export enum PublicURLModel {
  HOME = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  ROUTE = '/map',
  ROUTE_TRIP = '/map/:tripId',
  TRACKING_TRIP = '/tracking/:tripId',
  DISPATCH = '/dispatch',
  BLOG = '/blog',
  GET_APP = '/get',
  GET_APP_PLATFORM = '/get/:platform',
  PLACE_SEARCH_REDIRECT = '/places',
  PLACES_SEARCH = '/places/:city/:placeTypeOrCompany',
  PLACE_DETAILS = '/place/:slug',
  PLACE_DETAILS_REVIEW = '/place/:slug/review'
}

type URL_PARAMS<P extends string> = Record<P, string>;

export type URL_PARAMS_GET_APP_PLATFORM = URL_PARAMS<'platform'>;

export type URL_PARAMS_ROUTE_TRIP = URL_PARAMS<'tripId'>;

export type URL_PARAMS_PLACE_DETAILS = URL_PARAMS<'slug'>;

export type URL_PARAMS_PLACE_DETAILS_REVIEW = URL_PARAMS_PLACE_DETAILS;

export type URL_PARAMS_PLACE_SEARCH = URL_PARAMS<'city' | 'placeTypeOrCompany'>;
